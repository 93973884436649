import styled from "styled-components";

export const ContactPageContainer = styled.div`
    background: transparent;
    position: relative;
`
export const ContactPageContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    z-index: 1;
    position: relative;
`
export const CTextWrapper = styled.div`
    max-width: 1000px;
    padding-top: 0;
    
    @media screen and (max-width: 480px) {
        padding-top: 4vh;
        padding-bottom: 2vh;
        display: flex;
        flex-direction: column;
        margin-left: -5vw;
    }
`
export const ContactPageH1 = styled.h1`
    color: #fff;
    font-size: 5vh;
    line-height: 1.1;
    font-weight: 600;
    text-align: center;
    margin-bottom: 15vh;

    @media screen and (max-width: 480px) {
        font-size: 32px;
        margin-bottom: 2.5vh;
    }
`
export const ContactPageP = styled.p`
    font-size: 2.5vh;
    line-height: 3.75vh;
    max-width: 600px;
    color: #fff;
    text-align: center;

    @media screen and (max-width: 480px) {
        max-width: 280px;
        font-size: 2.2vh;
        margin-top: 60px;
    }
`
export const ContactPageBtnWrapper = styled.div`
    margin-top: 32px;
    display: center;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 480px) {
        margin-top: 0vh;
        margin-bottom:50vh;
    }
`
export const Button = styled.button`
    border-radius: 70px;
    background: ${({primary}) => (primary ? 'white' : 'black')};
    padding: 10px 50px;
    white-space: nowrap;
    color: ${({dark}) => (dark ? 'black' : 'white')};
    font-size: 16px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    border: 1px solid #fff;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: ${({primary}) => (primary ? 'black' : 'white')};
        color: ${({dark}) => (dark ? 'white' : 'black')};
        font-weight: 550;
        border: 1px solid rgb(71, 87, 102);
    }
`