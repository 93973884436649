import React from 'react';
import Main from './components/main/Main'
import Navbar from './components/nav/Navbar'
import './Home.css'


const Home = () => {
  return (
    <div id="home">
      <Navbar />
      <div id="main-section">
        <Main />
      </div>
    </div>
  );
};

export default Home;