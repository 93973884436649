import React, { useState, useEffect, useRef } from 'react';
import './WorkExperience.css';
import Uob from './uob/Uob';
import Ncs from './nus-ncs/nus_ncs';
import SopraSteria from './soprasteria/soprasteria';
import Nus from './nus/nus';

const WorkExperience = () => {
  const components = [
    <Uob key={1} />,
    <Ncs key={2} />,
    <SopraSteria key={3} />,
    <Nus key={4} />,
  ];

  const [activeIndex, setActiveIndex] = useState(0);
  const [isInView, setIsInView] = useState(false);
  const sectionRef = useRef(null);

  const handleNext = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % components.length);
  };

  const handlePrevious = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === 0 ? components.length - 1 : prevIndex - 1
    );
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsInView(entry.isIntersecting);
      },
      { threshold: 0.5 } 
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <div ref={sectionRef} className="work-experience-section">
      {isInView && (
        <div className="resume-download">
          <a
            href="__resume_nauman.pdf"
            download="Nauman_Resume.pdf"
            className="download-btn"
          >
            <span className="arrow">⬇</span>
            <span className="text">Download Resume</span>
          </a>
        </div>
      )}

      <div className="slider">
        <button className="prev" onClick={handlePrevious}>
          ❮
        </button>
        <div
          className="slider-wrapper"
          style={{
            transform: `translateX(-${activeIndex * 100}%)`,
          }}
        >
          {components.map((component, index) => (
            <div className="carousel-slide" key={index}>
              {component}
            </div>
          ))}
        </div>
        <button className="next" onClick={handleNext}>
          ❯
        </button>
      </div>
    </div>
  );
};

export default WorkExperience;
