import './App.css';
import React, { useState } from 'react';
import Home from './pages/home/Home'
import ContactPage from './pages/contact/ContactPage';
import Projects from './pages/project/Projects';
import WorkExperience from './pages/workexperience/WorkExperience';
import ScrollIndicator from './pages/scrollIndicator/ScrollIndicator'

function App() {
  const [currentPage, setCurrentPage] = useState('home'); // Track current page

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div id="main_container">
      <section id="home" onClick={() => handlePageChange('home')}>
        <Home />
      </section>
      <section id="projects" onClick={() => handlePageChange('projects')}>
        <Projects />
      </section>
      <section id="workexperience" onClick={() => handlePageChange('workexperience')}>
        <WorkExperience />
      </section>
      <section id="contact" onClick={() => handlePageChange('contact')}>
        <ContactPage />
      </section>
      {currentPage !== 'contact' && <ScrollIndicator />}
    </div>
  );
}
export default App;
