import React, { useState, useEffect } from 'react';
import SmoothScroll from 'smooth-scroll';
import './Navbar.css';

const Navbar = () => {
  const [currentPage, setCurrentPage] = useState("home");
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    new SmoothScroll('a[href*="#"]', {
      speed: 800,
      speedAsDuration: true,
    });
  }, []);

  const handleNavigation = (page) => {
    setCurrentPage(page);
    setIsMenuOpen(false); // Close menu on navigation
  };

  return (
    <nav className="navbar">
      <div className="logo">nauman.</div>
      
      {/* Overlay for dimming effect */}
      {isMenuOpen && <div className="overlay" onClick={() => setIsMenuOpen(false)}></div>}
      
      {/* Toggle the menu visibility when isMenuOpen is true */}
      <div className={`nav-links ${isMenuOpen ? 'open' : ''}`}>
        <a href="#workexperience" onClick={() => handleNavigation('workexperience')}>Work</a>
        <a href="#contact" onClick={() => handleNavigation('contact')}>Contact</a>
      </div>
      
      <div className="hamburger" onClick={() => setIsMenuOpen(!isMenuOpen)}>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </nav>
  );
};

export default Navbar;
