import React, { useState } from 'react';
import { motion } from 'framer-motion';
import './nus.css';
import nusLogo from './nus.png';

const Nus = () => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="App">
            <motion.div
                transition={{ layout: { duration: 0.5 } }}
                layout
                onClick={() => setIsOpen(!isOpen)}
                className="card"
            >
                <motion.h2 layout="position">NUS</motion.h2>

                {!isOpen && (
                    <>
                        {/* Company Logo */}
                    <motion.img
                         src={nusLogo} 
                        alt="National University of Singapore Logo"
                        className="companylogo"
                        layout
                    />
                    </>
                )}

                <motion.p className="duration" layout>
                    January 2020 - December 2020
                </motion.p>

                

                <motion.div
                    layout
                    initial={{ opacity: 0 }}
                    animate={{ opacity: isOpen ? 1 : 0 }}
                    transition={{ duration: 0.5 }}
                    className="content"
                >
                    {isOpen && (
                        <div>
                            <p>Taught Object-Oriented Programming as well as Data Structures in Java as a teaching assistant</p>
                            <p>Conduct weekly labs and provide feedback to students</p>
                            <p>https://drive.google.com/file/d/1b0ITQ0_gzQPRqpZrqw3YfYjOe7f4fjMH/view</p>
                        </div>
                    )}
                </motion.div>
            </motion.div>
        </div>
    );
};

export default Nus;
