import React, { useState } from 'react';
import './ScrollIndicator.css';

const ScrollIndicator = () => {
  const [hovered, setHovered] = useState(false);
  const [isLastSection, setIsLastSection] = useState(false); 

  const handleMouseEnter = () => {
    const sections = Array.from(document.querySelectorAll('section'));
    let closestDistance = Infinity;
    let nextSectionIndex;

    for (let i = 0; i < sections.length; i++) {
        const rect = sections[i].getBoundingClientRect();
        const center = (rect.top + rect.bottom) / 2; // Calculate the center of the section

        // Check if the section is in the viewport and calculate its distance to the center
        if (rect.top <= window.innerHeight && rect.bottom >= 0) {
          const distance = Math.abs(center - window.innerHeight / 2);
          if (distance < closestDistance) {
            closestDistance = distance;
            nextSectionIndex = (i+1) % sections.length;
          }
        }
    }
        if(nextSectionIndex) {
            setHovered(true)
        }
    };
  const handleMouseLeave = () => setHovered(false);


  // Scroll to the next section when clicked
  const scrollToNextSection = () => {
    const sections = Array.from(document.querySelectorAll('section'));
    let closestDistance = Infinity;
    let nextSectionIndex;

    for (let i = 0; i < sections.length; i++) {
        const rect = sections[i].getBoundingClientRect();
        const center = (rect.top + rect.bottom) / 2; // Calculate the center of the section

        // Check if the section is in the viewport and calculate its distance to the center
        if (rect.top <= window.innerHeight && rect.bottom >= 0) {
          const distance = Math.abs(center - window.innerHeight / 2);
          if (distance < closestDistance) {
            closestDistance = distance;
            nextSectionIndex = (i+1) % sections.length;
          }
        }
    }
    if (nextSectionIndex) {
      const nextSection = sections[nextSectionIndex];
      if (nextSection) {
        nextSection.scrollIntoView({ behavior: 'smooth' });
        setIsLastSection(false);
      }
    } else {
        setIsLastSection(true);
    }
  };

  return (
    <div
      className={`scroll-indicator ${hovered ? 'hovered' : ''} ${isLastSection ? 'disabled' : ''}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={scrollToNextSection}
    >
      <div className="v-shape"></div>
    </div>
  );
};

export default ScrollIndicator;
