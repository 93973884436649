import React, { useState } from 'react';
import { motion } from 'framer-motion';
import './soprasteria.css';
import soprasterialogo from './sopra.png'; 

const Soprasteria = () => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="App">
            <motion.div
                transition={{ layout: { duration: 0.5 } }}
                layout
                onClick={() => setIsOpen(!isOpen)}
                className="card"
            >
                <motion.h2 layout="position">SopraSteria</motion.h2>

                {!isOpen && (
                    <>
                        {/* Company Logo */}
                    <motion.img
                         src={soprasterialogo} 
                        alt="SopraSteria Logo"
                        className="companylogo"
                        layout
                    />
                    </>
                )}

                <motion.p className="duration" layout>
                    May 2021 - January 2022
                </motion.p>

                

                <motion.div
                    layout
                    initial={{ opacity: 0 }}
                    animate={{ opacity: isOpen ? 1 : 0 }}
                    transition={{ duration: 0.5 }}
                    className="content"
                >
                    {isOpen && (
                        <div>
                            <p>Creation of RBAC website providing access to over 100+ Company data dashboards (Angular + Spring Boot)</p>
                            <p>Design of relational schemas and optimization of existing queries in SQL Oracle RDBMS resulting in performance increase</p>
                            <p>Experience with Active Directory and SSO</p>
                        </div>
                    )}
                </motion.div>
            </motion.div>
        </div>
    );
};

export default Soprasteria;
