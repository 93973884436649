import React, { useState, useEffect } from 'react';
import './Main.css';

const Main = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Detect mobile screen width
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initialize on mount

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="container">
      <div className="centralized-container">
        <h1 className="header">
          {isMobile ? "Software Engineer" : "Hello, I am Nauman \n I am a Software Engineer"}
        </h1>
        <div>
          <p className="body">Hardworking and driven software engineer who enjoys positively impacting the lives of others through the use of technology.</p>
        </div>
      </div>
    </div>
  );
};

export default Main;
