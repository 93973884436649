import styled from "styled-components"

export const ProjectPageContainer = styled.div`
    background: transparent;
    position: relative;
`
export const ProjectPageContent = styled.div`
    display: grid;
    z-index: 1;
    height 100vh;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding: 0 1.5vw;
    justify-content: flex-start;
    margin-left: 20.5vh;
    z-index: 1;
    position: relative;

    @media screen and (max-width: 480px) {
        margin-left: 4vw;
        margin-right: 0;
        max-width: 100vw;
        display: flex;
    }
`
export const PTextWrapper = styled.div`
    max-width: 72vw;
    padding-top: 0;
    padding-bottom: 5vh;
    margin-top: 8vh;

    @media screen and (max-width: 480px) {
        max-width: 76vw;
    }
`
export const ProjectPageH1 = styled.h1`
    color: #fff;
    font-size: 4.8vh;
    line-height: 1.1;
    font-weight: 600;
    margin-bottom: 4.75vh;

    @media screen and (max-width: 480px) {
        margin-left:0;
        font-size: 28px;
        margin-bottom: 3vh;
    }
`
export const ProjectPageH2 = styled.h2`
    color: #fff;
    font-size: 4vh;
    line-height:0.8;
    font-weight: 500;
    margin-bottom: 5vh;

    @media screen and (max-width: 480px) {
        font-size: 2.4vh;
        line-height: 5.5vw;
        font-weight: 600;
        margin-bottom: 0;
    }
`
export const SectionWrapper = styled.div`
    margin-top: 35px;
    display: grid;
    grid-template-columns: 1.9fr 1.2fr;
    align-items: center;

    @media screen and (max-width: 480px) {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    }
`
export const ProjectPageP = styled.p`
    margin-bottom: 5vh;
    font-size: 2.2vh;
    line-height: 24px;
    color: #fff;
    align-items: center;
    text-align: right;

    @media screen and (max-width: 480px) {
        display: flex;
        text-align: left;
        flex-wrap: wrap;
        line-height: 20px;
        font-size: 1.7vh;
        margin-top: 4vh;
    }
`
export const ProjectImageWrapper = styled.div`
    display: flex;
    align-items: center;

    @media screen and (max-width: 480px) {
       align-items: start;
    }

`
export const Image = styled.img`
    height: 45vh;
    width: 80vh;  

    @media screen and (max-width: 480px) {
        height: 18vh;
        width: 35vh;
    }
`
export const BoldWord = styled.span`
    font-weight: bold;
`